html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

@font-face {
    font-family: 'MyWebFont';
    src: url('/public/icons/IcoMoon-Free.woff') format('woff');
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #f6f6f6;
    margin: 0px;
    line-height: normal;
    font-size: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none;
    color: inherit;
}

fieldset {
    border: 0;
    padding: 0 0 10px 0;
}

#root {
    height: 100vh;
}

button {
    color: inherit;
    font: inherit;
    font-family: inherit;
}

button[disabled] {
    cursor: default;
    opacity: 0.7;
}

.click-zoom {
    text-align: center;
}

.click-zoom input[type=checkbox] {
    display: none
  }
  
.click-zoom img {    
    transition: transform 0.25s ease;    
}
  
.click-zoom img:hover { 
        transform: scale(3);
}

.click-zoom.click-zoom-acr img:hover {
    transform: scale(1);    
}

.click-zoom-photo {
    padding-left: 5px;
    padding-right: 5px;
}